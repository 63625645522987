import { io } from "socket.io-client";

const URL =
  import.meta.env.MODE === "production" ? "/" : "http://127.0.0.1:443/";

export const socket = io(URL, {
  autoConnect: false,
  secure: import.meta.env.MODE === "production",
  transports: ["websocket"],
  port: 81,
});
