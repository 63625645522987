import {
  createTokensApi,
  deleteTokensApi,
  getConfigApi,
  getSettingsApi,
  getTokensApi,
  updateSettingsApi,
} from "@@services/SettingsService";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type SettingsData = {
  id?: number;

  d360ApiKey?: string;

  token?: string;
  api_key?: string;
  stripeApiKey?: string;
  stripeWebhookSecret?: string;

  waId?: string;

  profile?: string;

  kindofsystem?: string;

  default_language?: string;
  sendReadMessages?: boolean;
  showConsultation?: boolean;
};

type Config = {
  product?: "whatsbot" | "rasael" | "rasael-pro";
  whatsappPhone?: string;
  messengerId?: string;
};

export type Token = {
  id: number;
  token: string;
};

export type SettingsSlice = {
  data: SettingsData;
  config?: Config;
  tokens?: Array<Token>;
};

const initialState: SettingsSlice = {
  data: {},
  config: {},
  tokens: [],
};

export const getConfig = createAsyncThunk("settings/getConfig", async () => {
  const response = await getConfigApi();
  return response.data;
});

export const getSettings = createAsyncThunk(
  "settings/getSettings",
  async () => {
    const response = await getSettingsApi();
    return response.data;
  }
);

export const getTokens = createAsyncThunk("settings/getTokens", async () => {
  const response = await getTokensApi();
  return response.data;
});

export const deleteTokens = createAsyncThunk(
  "settings/deleteTokens",
  async (id: number) => {
    const response = await deleteTokensApi(id);
    return response.data;
  }
);
export const createTokens = createAsyncThunk(
  "settings/createTokens",
  async () => {
    const response = await createTokensApi();
    return response.data;
  }
);

export const updateSettings = createAsyncThunk(
  "settings/updateSettings",
  async (data: SettingsData) => {
    const response = await updateSettingsApi(data);
    return response.data;
  }
);

export const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.fulfilled, (state, action) => {
        state.data = action.payload.length ? action.payload[0] : {};
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.data = { ...action.payload };
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.config = action.payload;
      })
      .addCase(getTokens.fulfilled, (state, action) => {
        state.tokens = action.payload;
      })
      .addCase(deleteTokens.fulfilled, (state, action) => {
        state.tokens = state.tokens.filter(
          (token) => token.id !== action.payload.id
        );
      })
      .addCase(createTokens.fulfilled, (state, action) => {
        state.tokens.push(action.payload);
      });
  },
});

export const userPhone = (state: RootState) =>
  state.settingsSlice.config.whatsappPhone;
export const messengerId = (state: RootState) =>
  state.settingsSlice.config.messengerId;

export const settingsData = (state: RootState) => state.settingsSlice.data;
export const configData = (state: RootState) => state.settingsSlice.config;
export const tokens = (state: RootState) => state.settingsSlice.tokens;
